export const PowerballHistoryArray = [
    {
        id: 0,
        number: 1606503,
        eventCount: "2021-08-05[199회차]",
        betTime: "2021-08-05 16:31:03",
        type: "일반볼 홀/짝",
        historyType: 0,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "-5,000",
        win: false
    },
    {
        id: 1,
        number: 1606502,
        eventCount: "2021-08-05[199회차]",
        betTime: "2021-08-05 16:30:44",
        type: "일반볼 홀짝/소중대",
        historyType: 1,
        percentage: 4.85, 
        betAmount: "5,000",
        profit: "-5,000",
        win: false
    },
    {
        id: 2,
        number: 1606501,
        eventCount: "2021-08-05[199회차]",
        betTime: "2021-08-05 16:30:35",
        type: "일반볼 언오버",
        historyType: 2,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "+9,750",
        win: true
    },
    {
        id: 3,
        number: 1606500,
        eventCount: "2021-08-05[199회차]",
        betTime: "2021-08-05 16:30:26",
        type: "파워볼 홀/짝",
        historyType: 3,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "+9,750",
        win: true
    },
    {
        id: 4,
        number: 1606499,
        eventCount: "2021-08-05[198회차]",
        betTime: "2021-08-05 16:30:35",
        type: "일반볼 언오버",
        historyType: 2,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "+9,750",
        win: true
    },
    {
        id: 5,
        number: 1606498,
        eventCount: "2021-08-05[198회차]",
        betTime: "2021-08-05 16:30:44",
        type: "일반볼 홀짝/소중대",
        historyType: 1,
        percentage: 4.85, 
        betAmount: "5,000",
        profit: "-5,000",
        win: false
    },
    {
        id: 6,
        number: 1606497,
        eventCount: "2021-08-05[198회차]",
        betTime: "2021-08-05 16:30:35",
        type: "일반볼 언오버",
        historyType: 2,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "+9,750",
        win: true
    },
    {
        id: 7,
        number: 1606496,
        eventCount: "2021-08-05[197회차]",
        betTime: "2021-08-05 16:30:26",
        type: "파워볼 홀/짝",
        historyType: 3,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "+9,750",
        win: true
    },
    {
        id: 8,
        number: 1606495,
        eventCount: "2021-08-05[197회차]",
        betTime: "2021-08-05 16:30:35",
        type: "일반볼 언오버",
        historyType: 2,
        percentage: 1.95, 
        betAmount: "5,000",
        profit: "+9,750",
        win: true
    },
    {
        id: 9,
        number: 1606494,
        eventCount: "2021-08-05[197회차]",
        betTime: "2021-08-05 16:30:44",
        type: "일반볼 홀짝/소중대",
        historyType: 1,
        percentage: 4.85, 
        betAmount: "5,000",
        profit: "-5,000",
        win: false
    },
]

export const PowerballResultsArray = [
    {
        count: 288,
        chosenNum: "05, 06, 20, 21, 23",
        numberSum: "75",
        odd: true,
        powerball: "0",
        powerballOdd: false, 
        size: "중(65~80)",
        range: "A(0~2)"
    },
    {
        count: 287,
        chosenNum: "07, 08, 10, 11, 16",
        numberSum: "52",
        odd: false,
        powerball: "2",
        powerballOdd: false, 
        size: "소(15~64)",
        range: "A(0~2)"
    },
    {
        count: 286,
        chosenNum: "02, 04, 10, 13, 23",
        numberSum: "52",
        odd: false,
        powerball: "4",
        powerballOdd: true, 
        size: "소(15~64)",
        range: "B(3~4)"
    },
    {
        count: 285,
        chosenNum: "07, 08, 10, 11, 16",
        numberSum: "52",
        odd: false,
        powerball: "2",
        powerballOdd: false, 
        size: "소(15~64)",
        range: "A(0~2)"
    },
    {
        count: 284,
        chosenNum: "05, 06, 20, 21, 23",
        numberSum: "75",
        odd: false,
        powerball: "0",
        powerballOdd: true, 
        size: "중(65~80)",
        range: "A(0~2)"
    },
    {
        count: 283,
        chosenNum: "07, 08, 10, 11, 16",
        numberSum: "52",
        odd: false,
        powerball: "2",
        powerballOdd: false, 
        size: "소(15~64)",
        range: "A(0~2)"
    },
    {
        count: 282,
        chosenNum: "02, 04, 10, 13, 23",
        numberSum: "52",
        odd: false,
        powerball: "2",
        powerballOdd: true, 
        size: "소(15~64)",
        range: "B(3~4)"
    },
    {
        count: 281,
        chosenNum: "07, 08, 10, 11 ,16",
        numberSum: "52",
        odd: false,
        powerball: "2",
        powerballOdd: false, 
        size: "소(15~64)",
        range: "A(0~2)"
    },
    {
        count: 280,
        chosenNum: "02, 04, 10, 13 ,23",
        numberSum: "52",
        odd: false,
        powerball: "4",
        powerballOdd: true, 
        size: "소(15~64)",
        range: "B(3~4)"
    },
    {
        count: 279,
        chosenNum: "07, 08, 10, 11 ,16",
        numberSum: "52",
        odd: false,
        powerball: "2",
        powerballOdd: false, 
        size: "소(15~64)",
        range: "A(0~2)"
    },
]

export const PowerLadderResultsArray = [
    {
        id: 0,
        row1: 199,
        row1Sub: 3,
        row1Direction: "right",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 1,
        row1: 195,
        row1Sub: 4,
        row1Direction: "left",
        row2: 196,
        row2Sub: 4,
        row2Direction: "left",
        row3: 197,
        row3Sub: 4,
        row3Direction: "right",
        row4: 198,
        row4Sub: 4,
        row4Direction: "right",
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 2,
        row1: 193,
        row1Sub: 3,
        row1Direction: "left",
        row2: 194,
        row2Sub: 4,
        row2Direction: "left",
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 3,
        row1: 192,
        row1Sub: 4,
        row1Direction: "left",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 4,
        row1: 186,
        row1Sub: 3,
        row1Direction: "right",
        row2: 187,
        row2Sub: 4,
        row2Direction: "left",
        row3: 188,
        row3Sub: 4,
        row3Direction: "right",
        row4: 189,
        row4Sub: 3,
        row4Direction: "right",
        row5: 190,
        row5Sub: 4,
        row5Direction: "left",
        row6: 191,
        row6Sub: 4,
        row6Direction: "left",
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 5,
        row1: 183,
        row1Sub: 4,
        row1Direction: "right",
        row2: 184,
        row2Sub: 4,
        row2Direction: "right",
        row3: 185,
        row3Sub: 4,
        row3Direction: "left",
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 6,
        row1: 181,
        row1Sub: 3,
        row1Direction: "left",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 7,
        row1: 181,
        row1Sub: 4,
        row1Direction: "left",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 8,
        row1: 179,
        row1Sub: 3,
        row1Direction: "right",
        row2: 180,
        row2Sub: 4,
        row2Direction: "right",
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 9,
        row1: 178,
        row1Sub: 4,
        row1Direction: "left",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 10,
        row1: 175,
        row1Sub: 3,
        row1Direction: "left",
        row2: 176,
        row2Sub: 4,
        row2Direction: "left",
        row3: 177,
        row3Sub: 4,
        row3Direction: "left",
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 11,
        row1: 173,
        row1Sub: 4,
        row1Direction: "left",
        row2: 174,
        row2Sub: 4,
        row2Direction: "right",
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 12,
        row1: 172,
        row1Sub: 3,
        row1Direction: "left",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 13,
        row1: 169,
        row1Sub: 4,
        row1Direction: "left",
        row2: 170,
        row2Sub: 4,
        row2Direction: "right",
        row3: 171,
        row3Sub: 4,
        row3Direction: "left",
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 14,
        row1: 168,
        row1Sub: 3,
        row1Direction: "right",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 15,
        row1: 167,
        row1Sub: 4,
        row1Direction: "left",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 16,
        row1: 166,
        row1Sub: 3,
        row1Direction: "right",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 17,
        row1: 164,
        row1Sub: 3,
        row1Direction: "left",
        row2: 165,
        row2Sub: 4,
        row2Direction: "right",
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 18,
        row1: 162,
        row1Sub: 3,
        row1Direction: "left",
        row2: 163,
        row2Sub: 4,
        row2Direction: "left",
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 19,
        row1: 161,
        row1Sub: 4,
        row1Direction: "right",
        row2: null,
        row2Sub: null,
        row2Direction: null,
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },
    {
        id: 0,
        row1: 159,
        row1Sub: 3,
        row1Direction: "right",
        row2: 160,
        row2Sub: 4,
        row2Direction: "left",
        row3: null,
        row3Sub: null,
        row3Direction: null,
        row4: null,
        row4Sub: null,
        row4Direction: null,
        row5: null,
        row5Sub: null,
        row5Direction: null,
        row6: null,
        row6Sub: null,
        row6Direction: null,
        row7: null,
        row7Sub: null,
        row7Direction: null,
        row8: null,
        row8Sub: null,
        row8Direction: null,
        row9: null,
        row9Sub: null,
        row9Direction: null,
        row10: null,
        row10Sub: null,
        row10Direction: null,
    },

]